<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            :show-create-new-item-button="false"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            :showSearchBox="true"
            :searchPlaceholder="'Search driver name...'"
            @updateSearchValue="searchByValue"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";
export default {
  name: "drivers",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      baseUrl: "/drivers",
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "No. Deliveries", value: "deliveries" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      searchValue: "",
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/drivers/create");
    },

    editItem(item) {
      this.$router.push({
        name: "Edit Driver",
        params: {
          id: `${item.id}`,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.paginationDetails.loading = true;
        this.$http
          .get(url, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const driver = result.data.data[i];
              this.items.push({
                id: driver.id,
                firstName: driver.user.firstname,
                lastName: driver.user.lastname,
                status: driver.status,
                deliveries: driver.deliveries.length || 0,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;
        const body = [
          {
            field: "name",
            operator: "like",
            value: this.searchValue,
          },
        ];

        this.$http
          .post(url, body, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const driver = result.data.data[i];
              this.items.push({
                id: driver.id,
                firstName: driver.user.firstname,
                lastName: driver.user.lastname,
                status: driver.status,
                deliveries: driver.deliveries.length || 0,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    searchByValue(val) {
      this.searchValue = val;

      if (!val) {
        this.fetchDataByUrl("/drivers");
        return;
      }

      const body = [
        {
          field: "name",
          operator: "like",
          value: val,
        },
      ];

      this.$http
        .post("/drivers/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const driver = result.data.data[i];
            this.items.push({
              id: driver.id,
              firstName: driver.user.firstname,
              lastName: driver.user.lastname,
              status: driver.status,
              deliveries: driver.deliveries.length || 0,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.error("An error occurred while searching drivers.");
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(`/drivers/${item.id}`, body, this.sessionStore.getHttpConfig)
        .then(() => {
          this.fetchDataByUrl("/drivers");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.error("An error occurred while updating the status.");
        });
    },

    updateSelectedPage(newValue) {
      this.fetchDataByUrl(this.paginationDetails.links[newValue].url);
    },
  },
  mounted() {
    this.fetchDataByUrl(this.baseUrl);
  },
};
</script>
